import React, { useState, useEffect, useMemo } from 'react';
import './Layout.scss';
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Typography, Grid, Chip } from '@mui/material';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3',
    },
    dark: {
      main: '#111111',
    },
    background: {
      default: '#0d2237',
      paper: '#0d2237',
      warning: '#f5c44e'
    },
  },
});

const Layout = () => {
  const [loginStatus, setLoginStatus] = useState("");
  const [init, setInit] = useState(false);

  useEffect(() => {
    const loginStat = localStorage.getItem("course");
    if (loginStat) {
      setLoginStatus(loginStat);
    }

    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const callLoginAPi = (course) => {
    localStorage.setItem("course", course);
    setLoginStatus(course);
  };

  const particlesOptions = useMemo(() => ({
    background: {
      color: { value: "transparent" },
    },
    particles: {
      number: { value: 30 },
      size: { value: { min: 1, max: 5 } },
      color: { value: '#f5c44e' },
      links: {
        color: '#f5c44e',
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        enable: true,
        speed: 6,
        direction: "none",
        outModes: { default: "bounce" },
      },
    },
    detectRetina: true,
  }), []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{background: "#eeeeee", position: "fixed", width: "100%", overflow: "scroll", height: "100vH"}}>
        <Header />
        <Outlet />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

Layout.propTypes = {};

export default Layout;
