import React from 'react';
import PropTypes from 'prop-types';
import './Initial.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Canvas } from '@react-three/fiber';
import { Sphere, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useMediaQuery, Card, CardActions, CardContent, TextField, Container, Modal } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography, Chip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginForm from '../LoginForm/LoginForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faReact, faAndroid, faAppStoreIos } from '@fortawesome/free-brands-svg-icons';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' },
    // Add more theme customizations as needed
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 6,
  color: "#000000"
};

const Initial = () => {
  const [courseStatus, setCourseStatus ] = React.useState("se");
  const [init, setInit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  var items = [
    {
        src: "./backImg.jpg",
        alt: "Probably the most random thing you have ever seen!",
        description: "Probably the most random thing you have ever seen!",
    },
    {
        src: "./logo.jpg",
        alt: "Hello World!",
        description: "Hello World!"
    }
  ]

  React.useEffect(() => {
      let course = localStorage.getItem("course");
      setCourseStatus(course);

      initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      }).then(() => {
        setInit(true);
      });
  }, [courseStatus]);

  const particlesOptions = React.useMemo(() => ({
    background: {
      color: { value: "transparent" },
    },
    particles: {
      number: { value: 30 },
      size: { value: { min: 1, max: 5 } },
      color: { value: '#f5c44e' },
      links: {
        color: '#f5c44e',
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        enable: true,
        speed: 6,
        direction: "none",
        outModes: { default: "bounce" },
      },
    },
    detectRetina: true,
  }), []);


  const LoginCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component="h4" sx={{color: theme.palette.primary.main}}>
          Already Enrolled? Login Below to Book Classes, Check Attedance, Recordings and More
        </Typography>
        <Typography variant="h6" component="div">
          <Box sx={{ flexGrow: 1, mx: 2 , textAlign: "left", marginTop: "30px"}}>
            <label>Email</label>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Email"
              fullWidth
            />
          </Box>
        </Typography>
        <Typography variant="h6" component="div">
          <Box sx={{ flexGrow: 1, mx: 2 , textAlign: "left", marginTop: "30px"}}>
            <label>Password</label>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Email"
              fullWidth
              type="password"
            />
          </Box>
        </Typography>
      </CardContent>
      <CardActions sx={{ flexGrow: 1, mx: 2, justifyContent: "center", display: "flex", mb: 2 }}>
        <Button variant="contained" className='btn'>
          Login Now
        </Button>
      </CardActions>

    </React.Fragment>
  );

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{mt: '30px'}}>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Box sx={{ width: '100%', height: '80vH' }} className="perfect-center " sx={{ flexGrow: 1, textAlign: "center", padding: "30px", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Box sx={{ textAlign: "justify", display: "flex", flexDirection: "column", alignItems: "center" }} className="perfect-center">
                <Typography variant="h3" component="div" sx={{fontWeight: "100", fontSize: {lg : "300%", xs : "184%"}, textAlign: "center"}} color="#ffffff">
                  Free Online <span style={{color: theme.palette.primary.main}}>School Management</span> Software
                </Typography>
                <Typography variant="h6" component="div" sx={{fontWeight: "100"}} color="#ffffff">
                  Now you can manage School, Exam, Attendance, Bus, CCTV, Classes, Notes, Staff, Salary all for Free.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "30px" }}>
                    <Button variant="contained" className='btn' onClick={handleOpen}>
                      <FontAwesomeIcon icon={faUserTie} className="FontAwesomeIcon" /> Login Now, It's Free
                    </Button>
                    <Button variant="contained" className='btn' sx={{background: "#fff"}}>
                      Click Here to Learn More
                    </Button>
                </Box>
              </Box>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
          <Box sx={{ ...style, position: 'relative' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
            />
            <LoginForm />
          </Box>    
        </Modal>
        </Grid>
        <Grid item lg={12}>
        {
          courseStatus === 'se' ? (
            <Box >
              <Typography variant="h6" component="p" sx={{ flexGrow: 1, textAlign: "left", padding: "30px 30px 0px 30px", display: "flex", justifyContent: "left"}}>
                Our Other Courses
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                  padding: "30px",
                  display: "flex",
                  flexWrap: "wrap", // Allow chips to wrap
                  justifyContent: { xs: "space-between", sm: "flex-start" } // Adjust justification based on screen size
                }}
                className="chip-cover"
              >
                <Chip
                  icon={<FontAwesomeIcon icon={faPython} />}
                  label="Python"
                  color="primary"
                  variant="outlined"
                  sx={{
                    flex: { xs: "1 1 100%", sm: "1 1 25%" }, // Full width on mobile, initial width on larger screens
                    mb: 2
                  }}
                />
                <Chip
                  icon={<FontAwesomeIcon icon={faReact} />}
                  label="React"
                  color="primary"
                  variant="outlined"
                  sx={{
                    flex: { xs: "1 1 100%", sm: "1 1 25%"  },
                    mb: 2
                  }}
                />
                <Chip
                  icon={<FontAwesomeIcon icon={faAndroid} />}
                  label="Android"
                  color="primary"
                  variant="outlined"
                  sx={{
                    flex: { xs: "1 1 100%", sm: "1 1 25%"  },
                    mb: 2
                  }}
                />
                <Chip
                  icon={<FontAwesomeIcon icon={faAppStoreIos} />}
                  label="IOS/Swift"
                  color="primary"
                  variant="outlined"
                  sx={{
                    flex: { xs: "1 1 100%", sm: "1 1 25%"  },
                    mb: 2
                  }}
                />
              </Box>

            </Box>
          ): (
            <></>
          )
        }
        </Grid>
      </Grid>
    </Box>
    </Container>
    </ThemeProvider>
  );
};

Initial.propTypes = {};

export default Initial;
