import React from 'react';
import './Header.scss';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useAuth } from '../../services/authContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Initial from '../Initial/Initial';
import { AppBar, Toolbar, IconButton, Typography, Box, Button, TextField, Chip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';

const drawerWidth = 240;
const navItems = ['Login', 'Sign Up'];
const navItemsLogout = ['Home', 'Careers'];

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#0d2237' },
    warning: { main: '#f5c44e'}
    // Add more theme customizations as needed
  },
});

const Header = (props) => {
  const [value, setValue] = React.useState(0);
  const { loginStatus } = useAuth();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src="https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png" alt="Logo" className="topLogo" />
      </Typography>
      <Divider />
      <List>
      {!loginStatus.token ? (
        <>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </>
      ) : (
        <>
          {navItemsLogout .map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </>
      )}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      {!loginStatus.token ? (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar component="nav" sx={{background : theme.palette.primary.main}}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                <img src="https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png" alt="Logo" className="topLogo" />
              </Typography>

              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map((item) => (
                  <Button variant="contained" color="warning" key={item} sx={{ marginLeft: '30px' }}>{item}</Button>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Box>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar component="nav" sx={{background : theme.palette.primary.main}}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                <img src="https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png" alt="Logo" className="topLogo" />
              </Typography>

              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItemsLogout.map((item) => (
                  <Button key={item} sx={{ color: '#fff' }}>{item}</Button>
                ))}
                <Button sx={{ color: theme.palette.warning.main }}> 
                  <Chip avatar={<Avatar color={theme.palette.warning.main }>{loginStatus.user.firstName.split("")[0]}</Avatar>} label="Avatar" label={loginStatus.user.firstName} sx={{ color: theme.palette.warning.main }} />
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Box>
      )}
    </ThemeProvider>
  );
};

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
