import React from 'react';
import './Admin.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControlLabel, Switch, Button, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../services/authContext';
import CardContent from '@mui/material/CardContent';
import { RegisterApi } from '../../services/apis';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3',
    },
    background: {
      default: '#f5c44e',
      paper: '#f5c44e',
    },
  },
});

const Admin = () => {
  const { loginStatus, setLoginStatus } = useAuth();

  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [is_staff, setIsStaff] = React.useState(false);
  const [is_superuser, setIsSuperuser] = React.useState(false);
  const [register, setRegister] = React.useState(null); // Set initial state to null

  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");

    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
  }, [setLoginStatus]);

  const userNameHandler = (e) => {
    setUsername(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const staffHandler = (e) => {
    setIsStaff(e.target.checked); // Use checked for Switch
  };

  const superUserHandler = (e) => {
    setIsSuperuser(e.target.checked); // Use checked for Switch
  };

  const callRegisterAPi = () => {
    RegisterApi({ username, password, email, is_staff, is_superuser })
      .then(response => {
        setRegister(response); // Ensure response has the necessary fields
      })
      .catch(error => {
        console.error("Registration failed:", error);
      });
  };

  return (
    <>
      {loginStatus.token ?
        <>
          {loginStatus.user.is_superuser ?
            <ThemeProvider theme={theme}>
              <div className="Admin perfect-center" data-testid="Admin">
                <Box sx={{ width: '100%', maxWidth: '100%', textAlign: 'center' }} className="perfect-center">
                  <TextField
                    fullWidth
                    required
                    label="Username"
                    className='register-form'
                    value={username}
                    onChange={userNameHandler}
                    sx={{ marginBottom: 3 }}
                  />
                  <TextField
                    fullWidth
                    required
                    label="Email"
                    type='email'
                    className='register-form'
                    value={email}
                    onChange={emailHandler}
                    sx={{ marginBottom: 3 }}
                  />
                  <TextField
                    fullWidth
                    required
                    label="Password"
                    type='password'
                    className='register-form'
                    value={password}
                    onChange={passwordHandler}
                    sx={{ marginBottom: 3 }}
                  />
                  <FormControlLabel
                    control={<Switch checked={is_staff} onChange={staffHandler} />}
                    label="Is Staff"
                  />
                  <FormControlLabel
                    control={<Switch checked={is_superuser} onChange={superUserHandler} />}
                    label="Is Superuser"
                  />
                  <Button variant="contained" className='btn' sx={{ marginTop: "30px" }} onClick={callRegisterAPi}>
                    Create User
                  </Button>
                  {register ? ( // Check if register has a truthy value
                    <CardContent>
                      <Typography variant="h5" component="div">
                        New User Created
                      </Typography>
                      <Typography variant="body2" component="div">
                        Username: {register.user.username || "N/A"}
                        <br />
                        Email: {register.user.email || "N/A"}
                        <br />
                        Is Staff: {register.user.is_staff ? "Yes" : "No"}
                        <br />
                        Is Superuser: {register.user.is_superuser ? "Yes" : "No"}
                      </Typography>
                    </CardContent>
                  ) : null}
                </Box>
              </div>
            </ThemeProvider>
            : null
          }
        </>
        : null
      }
    </>
  );
};

export default Admin;
