import React from 'react';
import PropTypes from 'prop-types';
import './AdminDashBoard.scss';
import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { Link } from "react-router-dom";
import { ListProductApi, ListSubjectApi, ListFeesApi, ListTeachersApi } from '../../services/apis';
import { useAuth } from '../../services/authContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const AdminDashBoard = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [listOfSubjects, setListOfSubjects] = React.useState([]);
  const [listOfFees, setListOfFees] = React.useState([]);
  const [listOfTeacher, setListOfTeacher] = React.useState([]);

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#e4b648' },
      warning: { main:  '#e4b648'}
      // Add more theme customizations as needed
    },
  });

  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");
    if (loginStat) {
      const parsedLoginStat = JSON.parse(loginStat);
      setLoginStatus(parsedLoginStat);

      // Only fetch products if the user is a superuser
      if (parsedLoginStat.user && parsedLoginStat.user.is_superuser) {
        const fetchListProducts = async () => {
          try {
            const response = await ListProductApi();
            setListOfUsers(response);
          } catch (error) {
            console.error("Error fetching users:", error);
          }
        };

        fetchListProducts();
      }

      const fetchListSubjects = async () => {
        try {
          const response = await ListSubjectApi();
          setListOfSubjects(response);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };

      fetchListSubjects();

      const fetchListFees = async () => {
        try {
          const response = await ListFeesApi();
          setListOfFees(response);
          console.log(listOfFees);
        } catch (error) {
          console.error("Error fetching Fees:", error);
        }
      };

      fetchListFees();

      const fetchListTeacher = async () => {
        try {
          const response = await ListTeachersApi();
          setListOfTeacher(response);
          console.log(listOfTeacher);
        } catch (error) {
          console.error("Error fetching Teacher:", error);
        }
      };

      fetchListTeacher();
    }
  }, [setLoginStatus]); // Only depend on setLoginStatus

  return (
    <ThemeProvider theme={theme}>
      <div className="AdminDashBoard" data-testid="AdminDashBoard">
        <Box sx={{ flexGrow: 1, paddingTop: "20px" }}>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              textAlign: "left",
              color: "#2c3e50",
              padding: "10px 20px",
              fontWeight: "600",
              letterSpacing: "1px",
              borderBottom: "2px solid #bdc3c7",
            }}
          >
            Administrative Section
          </Typography>

          <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Link to="/class" className="linkTag" style={{ textDecoration: "none" }}>
                <Card sx={{
                  backgroundColor: "#f39c12", // Dark yellow-orange
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "20px",
                  
                  ':hover': { backgroundColor: "#e67e22" }, // Darker orange on hover
                  boxShadow: "0px 4px 12px rgba(243, 156, 18, 0.3)",
                  transition: "all 0.3s ease-in-out",
                }}>
                  <CardContent>
                    <Typography variant="h5">
                      <LocalLibraryIcon fontSize="large" sx={{ color: "#f7f1e3" }} />
                    </Typography>
                    <Typography variant="h6" sx={{ marginTop: "10px", fontWeight: "500" }}>
                      {listOfUsers.length} {listOfUsers.length > 1 ? "Classes" : "Class"}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Link to="/subject" className="linkTag" style={{ textDecoration: "none" }}>
                <Card sx={{
                  backgroundColor: "#3498db", // Medium-dark blue
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "20px",
                  
                  ':hover': { backgroundColor: "#2980b9" }, // Darker blue on hover
                  boxShadow: "0px 4px 12px rgba(52, 152, 219, 0.3)",
                  transition: "all 0.3s ease-in-out",
                }}>
                  <CardContent>
                    <Typography variant="h5">
                      <LocalLibraryIcon fontSize="large" sx={{ color: "#ecf0f1" }} />
                    </Typography>
                    <Typography variant="h6" sx={{ marginTop: "10px", fontWeight: "500" }}>
                      {listOfSubjects.length} {listOfSubjects.length > 1 ? "Subjects" : "Subject"}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Link to="/fees" className="linkTag" style={{ textDecoration: "none" }}>
                <Card sx={{
                  backgroundColor: "#e74c3c", // Dark red
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "20px",
                  
                  ':hover': { backgroundColor: "#c0392b" }, // Darker red on hover
                  boxShadow: "0px 4px 12px rgba(231, 76, 60, 0.3)",
                  transition: "all 0.3s ease-in-out",
                }}>
                  <CardContent>
                    <Typography variant="h5">
                      <LocalLibraryIcon fontSize="large" sx={{ color: "#ecf0f1" }} />
                    </Typography>
                    <Typography variant="h6" sx={{ marginTop: "10px", fontWeight: "500" }}>
                      {listOfFees.length} {listOfFees.length > 1 ? "Fees" : "Fee"}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, paddingTop: "20px" }}>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              textAlign: "left",
              color: "#2c3e50",
              padding: "10px 20px",
              fontWeight: "600",
              letterSpacing: "1px",
              borderBottom: "2px solid #bdc3c7",
            }}
          >
            Teacher Section
          </Typography>

          <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Link to="/teacher" className="linkTag" style={{ textDecoration: "none" }}>
                <Card sx={{
                  backgroundColor: "#f39c12", // Dark yellow-orange
                  color: "#ffffff",
                  textAlign: "center",
                  padding: "20px",
                  
                  ':hover': { backgroundColor: "#e67e22" }, // Darker orange on hover
                  boxShadow: "0px 4px 12px rgba(243, 156, 18, 0.3)",
                  transition: "all 0.3s ease-in-out",
                }}>
                  <CardContent>
                    <Typography variant="h5">
                      <LocalLibraryIcon fontSize="large" sx={{ color: "#f7f1e3" }} />
                    </Typography>
                    <Typography variant="h6" sx={{ marginTop: "10px", fontWeight: "500" }}>
                      {listOfTeacher.length} {listOfTeacher.length > 1 ? "Teachers" : "Teacher"}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};

AdminDashBoard.propTypes = {};

export default AdminDashBoard;
