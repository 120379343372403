import React from 'react';
import PropTypes from 'prop-types';
import './LoginForm.scss';
import { useMediaQuery, Card, CardActions, CardContent, TextField, Box, Typography, Button, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../services/authContext'; 
import { LoginApi, VerifyOtpApi } from '../../services/apis';  // Add API for sending OTP

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' },
    // Add more theme customizations as needed
  },
});

const LoginForm = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [phoneNumber, setPhoneNumber] = React.useState(""); // Phone number for OTP
  const [otp, setOtp] = React.useState(""); // OTP input by user
  const [otpSent, setOtpSent] = React.useState(false);  // Track if OTP was sent
  const [loading, setLoading] = React.useState(false); // Track loading status for OTP send

  // Function to send OTP to the user's phone number
  const handleSendOtp = () => {
    setLoading(true);  // Start loading indicator
    LoginApi({ phone: phoneNumber })
      .then(response => {
        alert("OTP sent successfully!");
        setOtpSent(true);  // OTP has been sent
        setLoading(false);  // Stop loading indicator
      })
      .catch(error => {
        alert("Error sending OTP. Please try again.");
        setLoading(false);  // Stop loading indicator on error
      });
  };

  // Function to handle login (verify OTP)
  const handleLogin = () => {
    // Send login request with phone number and OTP
    VerifyOtpApi({ phone: phoneNumber, otp: otp }) // We treat OTP as password
      .then(response => {
        localStorage.setItem("loginCred", JSON.stringify(response));
        setLoginStatus(response);
      })
      .catch(error => {
        alert(error);
      });
  };

  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
  }, [setLoginStatus]);

  const LoginCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" component="h4" sx={{ color: theme.palette.primary.main }}>
          Already Enrolled? Login Below to Book Classes, Check Attendance, Recordings and More
        </Typography>
        <Typography variant="h6" component="div">
          <Box sx={{ flexGrow: 1, mx: 2, textAlign: "left", marginTop: "30px" }}>
            <label>Phone Number</label>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Phone Number"
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Box>
        </Typography>
        {otpSent ? (
          <Typography variant="h6" component="div">
            <Box sx={{ flexGrow: 1, mx: 2, textAlign: "left", marginTop: "15px" }}>
              <label>Enter OTP</label>
              <TextField
                variant="outlined"
                size="small"
                placeholder="OTP"
                fullWidth
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Box>
          </Typography>
        ) : null}
      </CardContent>
      <CardActions sx={{ flexGrow: 1, mx: 2, justifyContent: "center", display: "flex", mb: 2 }}>
        {!otpSent ? (
          <Button
            variant="contained"
            className="btn"
            onClick={handleSendOtp}
            disabled={loading}  // Disable button if loading
          >
            {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : "Send OTP"} {/* Show loader or text */}
          </Button>
        ) : (
          <Button variant="contained" className="btn" onClick={handleLogin}>
            Login Now
          </Button>
        )}
      </CardActions>
    </React.Fragment>
  );

  return (
    <div className="LoginForm" data-testid="LoginForm">
      <Box sx={{ minWidth: 275, display: "flex", justifyContent: "center", width: "100%" }} className="perfect-center">
        <Card variant="outlined">{LoginCard}</Card>
      </Box>
    </div>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
