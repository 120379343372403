import React from 'react';
import './Home.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoginApi } from '../../services/apis';
import { useAuth } from '../../services/authContext'; // Import the custom hook
import { Grid2, Container } from '@mui/material';
import Status from '../Status/Status';
import Snackbar from '@mui/material/Snackbar';
import  Initial  from '../Initial/Initial';
import AdminDashBoard from '../AdminDashBoard/AdminDashBoard';

const Home = () => {
  const { loginStatus, setLoginStatus } = useAuth(); // Use the context
  const [username, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [myGreeting, setMyGreeting] = React.useState("Hello");

  const userNameHandler = (e) => {
    setUserName(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const callLoginAPi = () => {
    LoginApi({ username, password })
      .then(response => {
        localStorage.setItem("loginCred", JSON.stringify(response));
        setLoginStatus(response);
      })
      .catch(error => {
        alert(error);
      });
  };

  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");
    const greetings = [
      "Hello",         // English
      "नमस्ते",       // Hindi
      "வணக்கம்",      // Tamil
      "ಹೆಲೋ",        // Kannada
      "സുപ്രഭാതം",   // Malayalam
    ];
    let i = 0;
    setInterval(() => {
      if (i < greetings.length) {
        setMyGreeting(greetings[i]);
        i++;
      } else {
        i = 0;
      }
    }, 2000);

    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
  }, [setLoginStatus]);

  return (
    <Box className="full-height perfect-center"
      sx={{
        background: !loginStatus.token ? 'linear-gradient(to top, rgb(5, 25, 45), rgb(22 48 74));' : "",
        backgroundSize: "cover",
        backgroundPosition: "bottom left"
      }}
    >
      <div className='mainlogo perfect-center'>
        {loginStatus.token ? (
          <AdminDashBoard loginDetails={loginStatus}/>
        ) : (
          <Initial />
        )}
      </div>
    </Box>
  );
};

export default Home;
