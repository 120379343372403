import React from 'react';
import PropTypes from 'prop-types';
import './Status.scss';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { ListUsersApi, CreateTaskApi, ListTaskApi } from '../../services/apis';
import { TextField, Button, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3',
    },
    background: {
      default: '#0d2237',
      paper: '#0d2237',
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Status = (props) => {
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [heading, setHeading] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentLink, setPaymentLink] = React.useState("");

  React.useEffect(() => {
    if (props.loginDetails.user.is_superuser) {
      const fetchListUsers = async () => {
        try {
          const response = await ListUsersApi();
          setListOfUsers(response);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };

      fetchListUsers();
    } else {

      const fetchListTask = async () => {
        try {
          const response = await ListTaskApi();
          setListOfUsers(response);
        } catch (error) {
          console.error("Error fetching Task:", error);
        }
      };

      fetchListTask();
    }

  }, []);

  const filteredUsers = listOfUsers.filter(user =>
    user.username.toLowerCase().includes(filter.toLowerCase()) ||
    user.email.toLowerCase().includes(filter.toLowerCase())
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = (username) => {
    setSelectedUser(username);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleCreateTask = async () => {
    try {
      const response = await CreateTaskApi({
        username: selectedUser,
        taskHeading: heading,
        taskDescription: description,
        amount: amount,
        paymentLink: paymentLink
      });
      console.log("Task created successfully:", response);
      // Optionally reset form fields here
      handleClose();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="Status" data-testid="Status">
        {props.loginDetails.user.is_superuser ? (
          <div className='table-cover'>
            <TextField
              fullWidth
              label="Filter User"
              id="fullWidth"
              value={filter}
              sx={{ marginBottom: 3 }}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Table>
              <Thead>
                <Tr>
                  <Th>UserName</Th>
                  <Th>Email</Th>
                  <Th>Task</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers.map((item) => (
                  <Tr key={item.id}>
                    <Td>{item.username}</Td>
                    <Td>{item.email}</Td>
                    <Td><Button variant="contained" className='btn' onClick={() => handleOpen(item.username)}>Create</Button></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ ...style, position: 'relative' }}>
                <CloseIcon
                  onClick={handleClose}
                  style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                />
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                  Create Paid Task for {selectedUser}
                </Typography>
                <TextField
                  fullWidth
                  required
                  label="Task Heading"
                  className='register-form'
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  fullWidth
                  required
                  label="Task Description"
                  className='register-form'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  fullWidth
                  required
                  label="Task Amount"
                  className='register-form'
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  fullWidth
                  required
                  label="Payment Link"
                  className='register-form'
                  value={paymentLink}
                  onChange={(e) => setPaymentLink(e.target.value)}
                  sx={{ marginBottom: 3 }}
                />
                <Button variant="contained" className='btn' onClick={handleCreateTask}>Create Task</Button>
              </Box>
            </Modal>
          </div>
        ) : (
          <div className="task-cover">
            {filteredUsers.map((item) => (
              <>
                <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={item.taskHeading}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                          >
                            {item.taskDescription}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {item.amount > 0 ?
                  <Button component="a" href={item.paymentLink}>
                    Pay Rs. {item.amount}
                  </Button>
                  :
                  <></>
                  }
                </List>
                <Divider variant='inset' sx={{ marginLeft: "0px" }} />
              </>
            )
            )}
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

Status.propTypes = {
  loginDetails: PropTypes.shape({
    user: PropTypes.object.isRequired,
    token: PropTypes.string,
  }),
};

export default Status;
