import React from 'react';
import PropTypes from 'prop-types';
import './CourseManagement.scss';
import { useAuth } from '../../services/authContext';
import { Container, Box, Fab , TextField, Button, Modal, Typography, Divider, List, ListItem, IconButton, Accordion, AccordionActions, AccordionSummary, AccordionDetails, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ListProductApi, CreateTaskApi, ListTaskApi, CreateCourseApi , UpdateCourseApi, DeleteCourseApi, UpdateCourseImageApi, DeleteSectionApi } from '../../services/apis';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import { BEBase } from '../../constants/app.constant';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' },
    // Add more theme customizations as needed
  },
});

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
};

const UpdateStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vW",
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
};

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};

const options = [
  "Update",
  "Delete"
];

const ITEM_HEIGHT = 48;


const CourseManagement = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [heading, setHeading] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [paymentLink, setPaymentLink] = React.useState("");
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [productName, setProductName] = React.useState("");
  const [productDescription, setProductDescriptionn] = React.useState("");
  const [productCategory, setProductCategory] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [discountedPrice, setDiscountedPrice] = React.useState("");
  const [productPic1, setProductPic1] = React.useState();
  const [productPic2, setProductPic2] = React.useState();
  const [productPic3, setProductPic3] = React.useState();
  const [productPic4, setProductPic4] = React.useState();
  const [productPic5, setProductPic5] = React.useState();
  const [imgOpen, setImgOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState("")
  const [openedImage, setOpenedImage] = React.useState("");
  const [syllabus, setSyllabus] = React.useState();
  const [changeImgFlag, setChangeImgFlag] = React.useState("");
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now());
   // State to manage dynamically added textboxes
  const [textboxes, setTextboxes] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openme = Boolean(anchorEl);
  const [anchorElAccordion, setAnchorElAccordion] = React.useState(null);
  const openmeAccordion = Boolean(anchorElAccordion);
  const [selectedDropDown, setSelectedDropDown] = React.useState("");
  const [deleteSectionOpen, setDeleteSectionOpen] = React.useState(false);
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false);
  const [openMenuId, setOpenMenuId] = React.useState(null); 


  const handleClick = async (event, selectID) => {
    console.log("ancholEl", event, event.currentTarget, selectID);
    setAnchorEl(event.currentTarget);
    setSelectedDropDown(selectID);
  };

  const handleAccordianClick = (event, selectID) => {
    event.stopPropagation();
    console.log("ancholElAccordian", event, event.currentTarget, selectID);
    setAnchorElAccordion(event.currentTarget); // Set the anchor for the accordion menu
    setOpenMenuId(selectID); // Set the id for the menu being opened
  };

  const handleCloseMe = () => {
    setAnchorEl(null);
  };

  const handleCloseMeAccordion = () => {
    setAnchorElAccordion(null);
    setOpenMenuId(null);
  };
  
  // Function to add a new textbox (up to a limit of 10)
  const handleAddTextbox = () => {
    if (textboxes.length < 10) {
      setTextboxes([...textboxes, '']);
    }
  };
 
   // Function to handle changes in dynamically added textboxes
   const handleTextboxChange = (index, value) => {
     const updatedTextboxes = [...textboxes];
     updatedTextboxes[index] = value;
     console.log("getupdated", updatedTextboxes);
     setTextboxes(updatedTextboxes);
   };

  const handleRemoveTextbox = (index) => {
    const updatedTextboxes = textboxes.filter((_, i) => i !== index);
    setTextboxes(updatedTextboxes);
  };

  const handleAction = async (action) => {
    if((action.toLowerCase() === "delete")) {
      setDeleteSectionOpen(true);
    }
  };

  const handleAccordianAction = async (action, item) => {
    console.log("getitemmm", item)
    if ((action.toLowerCase() === "delete")) {
      setDeleteProductOpen(true);
      setSelectedID(item.id);
    } else if ((action.toLowerCase() === "update")) {
      const textBoxArr = [];
      setSelectedID(item.id);
      setProductName(item.productName);
      setProductDescriptionn(item.productDescription);
      item.sections.forEach((element, index) => {
        textBoxArr[index] = element.section_text;
      });
      console.log(textBoxArr)
      setTextboxes(textBoxArr)
      handleOpen()
      //setDeleteProductOpen(true);
    } 
  }

  const handleSectionDelete = async (action) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedDropDown.id);
  
      // Assuming DeleteCourseApi expects formData
      const response = await DeleteSectionApi(formData);
      
      console.log("Product deleted successfully:", response);
      handleClose();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  }
  

  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
    if (listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListProducts = async () => {
        try {
          const response = await ListProductApi();
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
    }

  }, [loginStatus]);

  const handleUpdateTask = async () => {
    try {

      const formData = JSON.stringify({
        'id': selectedID,
        'productName': productName,
        'productDescription': productDescription,
        'section_text': textboxes
      });

      const response = await UpdateCourseApi(formData);
      console.log("Product created successfully:", response);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error creating Product:", error);
    }
  }

  const filteredUsers = listOfUsers.filter(user =>
    user.productName.toLowerCase().includes(filter.toLowerCase())
  );


  const [open, setOpen] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleImageOpen = (path) => {
    setOpenedImage(path);
    setImgOpen(true);
    setImageCacheKey(Date.now());
  };
  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
  }

  const handleCreateTask = async () => {
    try {
      const response = await CreateTaskApi({
        username: selectedUser,
        taskHeading: heading,
        taskDescription: description,
        amount: amount,
        paymentLink: paymentLink
      });
      console.log("Task created successfully:", response);
      // Optionally reset form fields here
      handleClose();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const handleCreateProduct = async () => {
    try {
      const formData = JSON.stringify({
        'productName': productName,
        'productDescription': productDescription,
        'section_text': textboxes
      });
  
      const response = await CreateCourseApi(formData);
      console.log("Product created successfully:", response);
      window.location.reload();
      handleClose();
    } catch (error) {
      console.error("Error creating Product:", error);
    }
  };

  const handleUpdateImageTask = async (imagePath) => {
      console.log(imagePath);
      const formData = new FormData();
      formData.append('changeImgFlag', changeImgFlag)
      formData.append('imagePath', imagePath)
      var response = await UpdateCourseImageApi(formData)
  }

  const handleDeleteProduct = async (deleteId) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedID);
  
      // Assuming DeleteCourseApi expects formData
      const response = await DeleteCourseApi(formData);
      
      console.log("Product deleted successfully:", response);
      handleClose();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  };
  
  

  return (
    <ThemeProvider theme={theme}>
      <Box className="CourseManagement" data-testid="CourseManagement" sx={{ padding: '90px 0px', display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
        {loginStatus.token && (
          <Box>
            {loginStatus.user.is_superuser ? (
              <div className='table-cover'>
                <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                  <Fab color="primary" aria-label="add" onClick={handleOpenCreate} sx={{position: "fixed", right: "30px", bottom: "70px"}}>
                    <AddIcon />
                  </Fab>
                </Box>
                <TextField
                  fullWidth
                  label="Filter Class"
                  id="fullWidth"
                  value={filter}
                  sx={{ marginBottom: 3 }}
                  onChange={(e) => setFilter(e.target.value)}
                />
                {filteredUsers.map((item) => (
                <Accordion defaultExpanded key={item.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div>
                      {item.productName}
                      <br />
                      <small style={{color: "#666"}}>{item.productDescription}</small>
                    </div>
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMenuId === item.id ? 'long-menu' : undefined}
                        aria-expanded={openMenuId === item.id ? 'true' : undefined}
                        aria-haspopup="true"
                        sx={{
                          position: 'absolute',
                          right: 40,
                        }}
                        onClick={(e) => handleAccordianClick(e, item.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={openMenuId === item.id ? anchorElAccordion : null} // Set anchor for opened menu
                        open={openMenuId === item.id} // Open the menu for the selected item
                        onClose={handleCloseMeAccordion}
                        slotProps={{
                          paper: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                            },
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} onClick={(e) => handleAccordianAction(option, item)}>
                            {option}
                          </MenuItem>
                        ))}
                      </Menu>
                  </AccordionSummary>
                  <AccordionDetails>
                  {item.sections.map((getSection) => (
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        padding: '6px 12px', // Add padding for spacing inside
                        fontSize: '16px', // Font size for the label
                        borderRadius: '16px', // Rounded corners for a smooth appearance
                        border: '2px solid #e4b648', // Border color matching primary color
                        color: '#e4b648', // Text color matches the border
                        backgroundColor: 'transparent', // Transparent background
                        fontWeight: 600, // Slightly bolder text
                        cursor: 'pointer', // Pointer cursor for interactivity
                        transition: 'background-color 0.3s, color 0.3s', // Smooth transition on hover
                        marginLeft: "15px"
                      }}
                      onClick={(e) => e.stopPropagation()} // Prevent clicks on the label from triggering delete
                    >
                      {/* Label */}
                      <span>{getSection.section_text}</span>
                
                      {/* Delete Icon */}
                    </div>
                  ))}
                  </AccordionDetails>
                </Accordion>
                
                ))}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...UpdateStyle, position: 'relative' }}>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                    />
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                      Update Task
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      label="Class Name"
                      className='register-form'
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    <TextField
                      fullWidth
                      required
                      label="Class Description"
                      className='register-form'
                      value={productDescription}
                      onChange={(e) => setProductDescriptionn(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />

                    {textboxes.map((textbox, index) => (
                      <>
                        <TextField
                          key={index}
                          fullWidth
                          label={`Add Section ${index + 1}`}
                          value={textbox}
                          onChange={(e) => handleTextboxChange(index, e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />

                        <IconButton
                          onClick={() => handleRemoveTextbox(index)}
                          sx={{
                            position: 'absolute',
                            right: "30px",
                            marginTop: "8px",
                            color: 'red',
                            cursor: 'pointer',
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </>
                    ))}
                    <Button
                      variant="outlined"
                      onClick={handleAddTextbox}
                      disabled={textboxes.length >= 10} // Disable button if 10 textboxes already exist
                    >
                      Add Section
                    </Button>
                    <Button variant="contained" className='btn' onClick={handleUpdateTask}>Update Product</Button>
                  </Box>
                </Modal>
                <Modal
                  open={imgOpen}
                  onClose={() => setImgOpen(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...ImgStyle, position: 'relative' }}>
                    <CloseIcon
                      onClick={() => setImgOpen(false)}
                      style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                    />
                    <Box>
                      <img src={`${BEBase}media/${openedImage}?cache=${imageCacheKey}`} style={{ width: "100%" }} />
                    </Box>
                    <TextField
                      fullWidth
                      required
                      className='register-form'
                      type='file'
                      sx={{ marginBottom: 3 }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setChangeImgFlag(file);
                        }
                      }}
                    />
                    <Button variant="contained" className='btn' onClick={() => {
                      handleUpdateImageTask(openedImage);
                      setImgOpen(false); // Close the modal after the update
                    }}>Change Image</Button>
                  </Box>
                </Modal>
                <Modal
                  open={deleteProductOpen}
                  onClose={() => setDeleteProductOpen(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...ImgStyle, position: 'relative' }}>
                    <CloseIcon
                      onClick={() => setDeleteProductOpen(false)}
                      style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                    />
                    <h3>Are you Sure You want to delete this Class</h3>
                    <Button variant="contained" className='btn' onClick={() => {
                      handleDeleteProduct()
                    }}>Yes</Button>
                    <Button variant="contained" className='btn' onClick={() => setDeleteProductOpen(false)}>No</Button>
                  </Box>
                </Modal>
                <Modal
                  open={openCreate}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{overflow: 'auto'}}
                >
                  <Box sx={{ ...style, position: 'relative' }}>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
                    />
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 3 }}>
                      Create New Class
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      label="Class Name"
                      className='register-form'
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    <TextField
                      fullWidth
                      required
                      label="Class Description"
                      className='register-form'
                      value={productDescription}
                      onChange={(e) => setProductDescriptionn(e.target.value)}
                      sx={{ marginBottom: 3 }}
                    />
                    
                    {/* Dynamically added textboxes */}
                    {textboxes.map((textbox, index) => (
                      <>
                        <TextField
                          key={index}
                          fullWidth
                          label={`Add Section ${index + 1}`}
                          value={textbox}
                          onChange={(e) => handleTextboxChange(index, e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />

                        <IconButton
                          onClick={() => handleRemoveTextbox(index)}
                          sx={{
                            position: 'absolute',
                            right: "30px",
                            marginTop: "8px",
                            color: 'red',
                            cursor: 'pointer',
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </>
                    ))}
                    {/* Button to add a new textbox */}
                    <Button
                      variant="outlined"
                      onClick={handleAddTextbox}
                      disabled={textboxes.length >= 10} // Disable button if 10 textboxes already exist
                    >
                      Add Section
                    </Button>
                    <Button variant="contained" className='btn' onClick={handleCreateProduct}>
                      Create Class
                    </Button>
                  </Box>
                </Modal>
                
              </div>
            ) : (
              <div className="task-cover">
                {filteredUsers.map((item) => (
                  <>
                    <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                    <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={item.taskHeading}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{ color: 'text.primary', display: 'inline' }}
                              >
                                {item.taskDescription}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {item.amount > 0 ?
                      <Button component="a" href={item.paymentLink}>
                        Pay Rs. {item.amount}
                      </Button>
                      :
                      <></>
                      }
                    </List>
                    <Divider variant='inset' sx={{ marginLeft: "0px" }} />
                  </>
                )
                )}
              </div>
            )}
              </Box>
            )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

CourseManagement.propTypes = {};

export default CourseManagement;
