import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import Admin from './components/Admin/Admin';
import Settings from './components/Settings/Settings';
import Terms from './components/Terms/Terms';
import Privacy from './components/Privacy/Privacy';
import CourseManagement from './components/CourseManagement/CourseManagement';
import SubjectManagement from './components/SubjectManagement/SubjectManagement';
import TransportManagement from './components/TransportManagement/TransportManagement';
import FeesManagement from './components/FeesManagement/FeesManagement';
import TeacherManagement from './components/TeacherManagement/TeacherManagement';
 
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path='/add_user' element={<Admin />} />
          <Route path='/settings' element={<Settings/>} />
          <Route path='/terms' element={<Terms/>} />
          <Route path='/privacy' element={<Privacy/>} />
          <Route path='/class' element={<CourseManagement />} />
          <Route path='/subject' element={<SubjectManagement />} />
          <Route path='/driver' element={<TransportManagement />} />
          <Route path='/fees' element={<FeesManagement />} />
          <Route path='/teacher' element={<TeacherManagement />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
