import React from 'react';
import './Footer.scss';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useAuth } from '../../services/authContext';
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const [value, setValue] = React.useState(0);

  const { loginStatus } = useAuth();
  const location = useLocation()

  React.useEffect(() => {
    switch (location.pathname) {
      case '/add_user':
        setValue(1);
        break;
      case '/settings':
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location]);

  return (
    <>
      {!loginStatus.token ?
        <div></div>
        :
        <div>
          {loginStatus.user.is_superuser ?
            <Box sx={{ flexGrow: 1, background: "#0d2238"}}>
              <BottomNavigation
                showLabels
                value={value}
                className='bn'
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                <BottomNavigationAction label="Home" icon={<RestoreIcon />} component={Link} to="/" />
                <BottomNavigationAction label="Settings" icon={<SettingsIcon />} component={Link} to="/settings" />
                <BottomNavigationAction label="New Client" icon={<PersonAddIcon />} component={Link} to="/add_user" />
              </BottomNavigation>
            </Box>
            :
            <Box sx={{ flexGrow: 1, background: "#0d2238" }}>
              <BottomNavigation
                showLabels
                value={value}
                className='bn'
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                <BottomNavigationAction label="Status" icon={<RestoreIcon />} component={Link} to="/" />
                <BottomNavigationAction label="Settings" icon={<SettingsIcon />} component={Link} to="/settings" />
              </BottomNavigation>
            </Box>
          }
        </div>
      }
    </>
  )
};

Footer.propTypes = {};

export default Footer;
